import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/gregoirethibaud/Desktop/Brusketa/Developpement/brusketa/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Container from './Container';
import { Playground, Props } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "container"
    }}>{`Container`}</h1>
    <p>{`Container component`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Container} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Container />'} __scope={{
      props,
      DefaultLayout,
      Container,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Container mdxType="Container" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      